var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"content"},[_c('div',{staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"domrelative wrapper_1200"},[_vm._m(2),_c('div',{staticClass:"domUn domFexidTop",style:(`height:` + _vm.maxHigth + `px`)},[_vm._m(3),_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/12/品聚切图01-htanc1o3ipk3.png","alt":""}})])])]),_c('div',[_c('div',{staticClass:"domrelative wrapper_1200"},[_vm._m(4),_c('div',{staticClass:"domUn domFexidTop",style:(`height:` + _vm.maxHigth + `px`)},[_vm._m(5),_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/12/品聚切图02-77xppffsqtbm.png","alt":""}})])])]),_c('div',{staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"domrelative wrapper_1200"},[_vm._m(6),_c('div',{staticClass:"domUn domFexidTop",style:(`height:` + _vm.maxHigth + `px`)},[_vm._m(7),_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/12/品聚切图03xx1x-xls0ff4un2ow.png","alt":""}})])])]),_c('div',[_c('div',{staticClass:"domrelative wrapper_1200"},[_vm._m(8),_c('div',{staticClass:"domUn domFexidTop",style:(`height:` + _vm.maxHigth + `px`)},[_vm._m(9),_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/12/品聚切图04-wxndr2kvbg09.png","alt":""}})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topImg"},[_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/01/品聚-1xaws0yr4flr.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"wrapper_1200 dom1"},[_c('p',{staticClass:"title"},[_vm._v("品聚SaaS")]),_c('p',{staticClass:"titleText"},[_c('span',[_vm._v("该平台围绕品牌供应商业务场景，以搭建供应商专属商品库，快速生成电子版产品手册，订单数字化管理等功能为核心，从而提升品牌供应商业务管理能力及工作效率。")]),_c('span',{staticClass:"icon1"},[_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/01/Vector-821c0ssjruuc.png","alt":""}})]),_c('span',{staticClass:"icon2"},[_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/01/Vectorxx1x-4dstvlpteupa.png","alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"domup"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("核心功能一")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"domup"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("核心功能二")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"domup"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("核心功能三")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"domup"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("核心功能四")]),_c('span')])
}]

export { render, staticRenderFns }